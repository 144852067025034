import React, { SVGProps } from 'react';

export const GMDYellow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="Layer_1"
    x="0px"
    y="0px"
    width={594}
    height={592.2}
    viewBox="0 0 594 592.2"
    xmlSpace="preserve"
    {...props}
  >
    <style>{".st1{fill-rule:evenodd;clip-rule:evenodd}"}</style>
    <path
      d="M292.9.5c-14.2 2.4-28.8 20.7-40.8 32.7L29 255.4c-22.9 22.9-44.3 37.2-14.1 67.4l55.5 55.3c25 25 49.4 49.1 74.4 74l111.6 111.1c6.3 6.3 12.2 11.7 18.1 18.5 4.6 5.3 15.7 11.8 26.9 10 12.8-2.1 29.9-22.2 40.5-32.8l129.8-129.5c6.5-6.4 12.4-12.2 18.9-18.7l74.5-73.9c6.1-6 12.7-12 18.4-18.3 5.5-6.1 12.3-13.9 10.1-26.7-2.3-13.5-21.7-29.7-32.6-40.7-12.6-12.6-24.5-24.7-37.2-37-12.7-12.4-24.6-24.5-37.2-37-12.9-12.9-24.5-24.4-37.2-37L337.8 29c-6.1-6.1-12.7-12-18.3-18.4-5.4-6.1-15.6-12-26.6-10.1h0z"
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#fad500"
      stroke="#000"
    />
    <path
      id="Path_2"
      className="st1"
      d="M294 10c13.6-2.7 30 18.2 41.5 29.7l144 143.5c6 6 11.5 12.3 17.4 18.3l71.5 71.3c4.6 4.6 13.8 11.4 15.5 20.1 2.3 11.8-7.4 18.5-12.3 23.3L374.1 513.1c-12.1 12.1-23.7 23.4-35.9 35.5-6 6-11.4 12.2-17.4 18.3-4.2 4.2-12.5 13.6-20.5 15.3-10.9 2.3-19.5-7.6-23.7-11.8L25.7 319.5C7.3 301.6 2.5 295.3 22.4 276c6.2-6.1 11.7-11.7 17.9-17.8l233-233C277.8 20.6 285 11.8 294 10zM292.9.5c-14.2 2.4-28.8 20.7-40.8 32.7L29 255.4c-22.9 22.9-44.3 37.2-14.1 67.4l55.5 55.3c25 25 49.4 49.1 74.4 74l111.6 111.1c6.3 6.3 12.2 11.7 18.1 18.5 4.6 5.3 15.7 11.8 26.9 10 12.8-2.1 29.9-22.2 40.5-32.8l129.8-129.5c6.5-6.4 12.4-12.2 18.9-18.7l74.5-73.9c6.1-6 12.7-12 18.4-18.3 5.5-6.1 12.3-13.9 10.1-26.7-2.3-13.5-21.7-29.7-32.6-40.7-12.6-12.6-24.5-24.7-37.2-37-12.7-12.4-24.6-24.5-37.2-37-12.9-12.9-24.5-24.4-37.2-37L337.8 29c-6.1-6.1-12.7-12-18.3-18.4-5.4-6.1-15.6-12-26.6-10.1z"
    />
    <path
      id="Path_1"
      className="st1"
      d="M398.7 255.4H194.9c0 34.8 9.4 59.3 27.9 75.4 30.9 27.2 75.6 40 117.4 22.2 23.5-10 43.5-26.5 52.4-50.9 5.3-14.4 6.5-30.2 6.1-46.7z"
    />
    <path
      id="Path_3"
      className="st1"
      d="M293.8 36.8c6.6-4.2 10.9 3 19.1 11.3 5.7 5.7 10.8 10.7 16.5 16.4l195.8 196c38.4 38.4 40.8 31.3 20.3 51.8L316.7 540.1c-16.3 16.3-17.4 22-27 12.2l-8-8L68.9 332.4c-5.2-5.2-30.1-28.7-32.2-32.7-3.2-6 1.3-8.5 4-11.1 2.9-2.8 5.1-5.1 8-8 5.7-5.7 10.7-10.8 16.4-16.4 7.1-7 27.1-26.3 32.2-32.6 7.8-9.7 146.8-146.5 163.2-162.9 5.8-5.8 26.9-27.8 33.3-31.9zm-.2-10.7c-8.8 2.4-28.9 24.8-36.1 32L37.2 277.5c-22.8 22.7-8.4 28.6 21.1 58.1l169.5 168.8c3 3 5.5 5.4 8.5 8.4l41.9 42.6c23.3 23.3 28.7 8.4 59.1-21.1l169-169.2c11.5-11.5 22.4-22.3 33.9-33.8 5.9-5.9 11-11.1 16.9-16.9 4.2-4.1 13.9-11.7 10.7-22.3-2.6-8.8-25.3-29.1-31.9-35.7L315.5 37c-4.3-4.4-11.5-13.7-21.9-10.9z"
    />
    <path
      id="Path_4"
      className="st1"
      d="M217.9 238.4c-1.6-1.5-.9.1-1.5-2.6 0-.8.1-1.7.3-2.5l8.1-.5c3.2.5 1.5.4 2.3.8l4.2 1.4c.9-3.9 1-4.4-.6-7.3-3.3-.4-2.6.9-6.9 1.2l-26.5-1.1c-1.8 2.8-1.6 3.2-.6 6.8 3.6 0 3.4-1.5 15.8-1.5l.2 5.2-17.8.1v13.2h204.4v-13.1l-19.4-.3V233c12.7 0 8.7 1.4 13.9 1.5 2.7-9.9-.4-5.7-15.9-5.7-4.4 0-9.8.4-13.6-.5-5.4-1.4-6.5-1.2-5.1 6.3 4.3 0 2.5-1.5 15.7-1.5l.3 5.2h-75.3l.1-5.2c9 0 12.2 1.7 14.4 1l.3-5.6c-4.6-1.4-20.2 2-31.8-.6-4.9-1.1-4.6 1.9-3.4 6.4 1.7.3 10.6-1.3 16.1-1l.2 4.9-77.9.2z"
    />
    <path
      id="Path_5"
      className="st1"
      d="M294.5 368.1l.1 75.8c-7.8 2.6-7.4 15.3 2.5 15.2 4.6 0 7-2 7.4-6.4.5-5.5-1.1-7.7-4.6-8.9v-76c4.1 2.8 53.1 64.5 60.3 70.6-2.2 6.8 1.3 14.1 8 16.6 11.6 3.7 13.7-15.7-.7-19.1-5.8-1.4-7.8-5.5-14.1-13.1l-44-50.8c-7.7-8.5-3.4-8.4-18.9-8.3l-18.8 21.4c-1.7 1.7-3.3 3.6-4.8 5.6l-29.1 32.9c-1.7 2.2-3.3 3.2-5 5.3-.9 1.1-1.1 1.7-2 2.7-4.6 5.5-5.4-.2-10.8 5.2-12 11.9 4 22.9 11.1 11 7.4-12.4-11.7 3.5 24.2-35.9l39.2-43.8z"
    />
    <path
      id="Path_6"
      className="st1"
      d="M327.9 148.2c-13.3 8.4-72.9 51.5-79 54.3 1.4 3.6 1.7 4 4 6.1l63-44.1c2-1.4 14.3-10.4 15.5-10.7.6-.1 13 5.3 17.6-4.3 4.2-8.6-8.9-23.1-18.1-12.4-3.4 4-2 6-3 11.1z"
    />
    <path
      id="Path_7"
      className="st1"
      d="M289.3 170.9c2.5-.9 4.8-2.4 6.6-4.4-4.5-3-9.4-6.5-14.3-10-3.7-2.6-11.2-8.1-15-9.6 1.2-19.2-17.8-18.6-21.1-4.7-1.3 5.6 1.7 8.4 5.1 10.5 6.3 3.9 8-.1 11.7 0 2 0 25.1 16.5 27 18.2z"
    />
    <path
      id="Path_8"
      className="st1"
      d="M301.2 179.1l33.6 22.8c3.6 2.5 8.1 6.1 12.2 7.9l3.7-5-43.2-30.4c-3 1.2-4.6 2.9-6.3 4.7z"
    />
    <path
      id="Path_9"
      className="st1"
      d="M217.9 238.4l-.5-4.7h9.7c-.8-.4.8-.4-2.3-.8l-8.1.5c-.2.8-.3 1.6-.3 2.5.6 2.7-.1 1 1.5 2.5z"
    />
  </svg>
);
