import React, { ReactNode } from 'react'
import { makeStyles, Typography, TypographyProps } from '@material-ui/core'

const useStyles = makeStyles({
  description: {
    wordBreak: 'break-word',
    whiteSpace: 'pre-wrap',
  },
})

const MATCH_URL = /(http[^\s]+|www[^\s]+)/
export const Description = ({ children, ...rest  }: TypographyProps) => {
  const classes = useStyles()
  const matchUrl = new RegExp(MATCH_URL)

  const stringToLink = (paragraph: ReactNode[], chunk: string, index: number) => {
    if (matchUrl.test(chunk)) {
      paragraph.push((
        <a target='_blank' key={index} href={chunk}>{chunk}</a>
      ))
    } else {
      paragraph.push(chunk)
    }
    return paragraph
  }

  const parseUrls = () => {
    if (typeof children === 'string') {
      const parts = children.split(matchUrl)

      return parts.reduce<ReactNode[]>(
        stringToLink,
        [],
      )
    } else {
      return children
    }
  }
 
  return (
    <Typography
      className={classes.description}
      {...rest}
    >
      {parseUrls()}
    </Typography>
  )
}
