import React, { useEffect, useState } from "react"
import axios, { AxiosPromise } from "axios"
import {
  Box,
  Grid,
  Button,
  makeStyles,
  CircularProgress,
} from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { FormRenderProps } from "react-final-form"
import { object } from "yup"

import { Alert } from "components/alert"
import { Form } from "components/final-form/form"
import { Field } from "components/final-form/field"
import { ProgressButton } from "components/progress-button"
import { TimeRangePicker, TimeInterval } from "components/time-range-picker"
import { getUserToken } from "services/auth/auth"
import { GetLocationTimeData } from "types"
import { isValue } from "utilities/helpers"

import { StepProps } from "./wizard"

const useStyles = makeStyles({
  container: {
    width: "100%",
    textAlign: "center",
  },
})

type Props = StepProps

export interface FormValues {
  interval?: TimeInterval
}

const getLocationTimeData = (
  locationId: string,
  excludedPerformanceId?: string
): AxiosPromise<GetLocationTimeData> => {
  return axios.get(
    `${process.env.GATSBY_API_URL}/locations/${locationId}/time?excludedPerformanceId=${excludedPerformanceId}`,
    {
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    }
  )
}

export const PerformanceTime = ({ onSubmit, previousStep, data }: Props) => {
  const [pickerProps, setPickerProps] = useState<GetLocationTimeData>()
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    ;(async () => {
      if (data.locationId) {
        let timeData
        if (data.performanceId) {
          setLoading(true)
          const { data: responseData } = await getLocationTimeData(
            data.locationId as string,
            data.performanceId
          )
          setLoading(false)
          timeData = responseData
        } else {
          setLoading(true)
          const { data: responseData } = await getLocationTimeData(
            data.locationId as string
          )
          setLoading(false)
          timeData = responseData
        }
        setPickerProps({
          cityHourFrom: timeData.cityHourFrom,
          cityHourTo: timeData.cityHourTo,
          takenTimeSlots: timeData.takenTimeSlots,
          takenTimeIntervals: timeData.takenTimeIntervals,
        })
      }
    })()
  }, [])

  const { t } = useTranslation()
  const classes = useStyles()

  const schema = object().shape({
    interval: object().test(
      "interval",
      t("validation.required"),
      async ({ hourFrom, hourTo }: TimeInterval) =>
        isValue(hourFrom) && isValue(hourTo)
    ),
  })
  const { hourFrom, hourTo } = data

  const PickTimeForm = ({
    handleSubmit,
    values,
    submitting,
    hasValidationErrors,
  }: FormRenderProps<FormValues>) => (
    <form noValidate onSubmit={handleSubmit}>
      <Grid container spacing={4} justifyContent="center" alignItems="center">
        {!isValue(values.interval?.hourFrom) &&
          !isValue(values.interval?.hourTo) && (
            <Grid item xs={12}>
              <Alert>{t("participate.time-range")}</Alert>
            </Grid>
          )}
        <Grid item className={classes.container}>
          {isLoading && <CircularProgress size={60} />}
          {!isLoading && (
            <Field
              name="interval"
              component={TimeRangePicker}
              startHour={pickerProps?.cityHourFrom}
              endHour={pickerProps?.cityHourTo}
              takenTimeSlots={pickerProps?.takenTimeSlots || []}
            />
          )}
        </Grid>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Button variant="outlined" onClick={previousStep}>
              {t("button.previous")}
            </Button>
          </Grid>
          <Grid item>
            <ProgressButton
              variant="contained"
              color="primary"
              type="submit"
              disabled={submitting || hasValidationErrors}
              progress={submitting}
            >
              {t("button.next")}
            </ProgressButton>
          </Grid>
        </Grid>
      </Grid>
    </form>
  )

  return (
    <Box p={1}>
      <Form
        onSubmit={onSubmit}
        validationSchema={schema}
        initialValues={{ interval: { hourFrom, hourTo } }}
        render={PickTimeForm}
      />
    </Box>
  )
}
