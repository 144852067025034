import React from "react"
import {
  Box,
  Grid,
  FormControlLabel,
  FormControl,
  FormHelperText,
  Checkbox,
  Link,
} from "@material-ui/core"
import axios, { AxiosResponse } from "axios"
import { navigate } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import blue from "@material-ui/core/colors/blue"
import { FormRenderProps } from "react-final-form"
import { object, string, boolean } from "yup"
import { useTranslation, Trans } from "react-i18next"

import { TextField } from "components/text-field"
import {
  withErrorNotification,
  SetError,
} from "components/hocs/with-error-notification"
import { getEnvURL } from "utilities"
import { Form } from "components/final-form/form"
import { Field } from "components/final-form/field"
import { PasswordField } from "components/password-field"
import { ProgressButton } from "components/progress-button"
import { UserContext } from "components/global-context"
import API_ENDPOINTS from "constants/api-endpoints"
import CLIENT_ROUTES from "constants/endpoints"
import { ForgotPasswordLink } from "components/forgot-password-link"

const useStyles = makeStyles({
  submitButton: {
    width: "50%",
  },
  ruleAgreementContainer: {
    padding: 10,
    margin: 0,
  },
  link: {
    color: blue[600],
  },
})

interface FormValues {
  email: string
  password: string
  name?: string
  phone?: string
  isRulesAgreed?: boolean
}

const RuleAgreement = (props: {
  disabled?: boolean
  onChange: (value: boolean) => void
  value: boolean
  error?: boolean
  helperText?: string
}) => {
  const classes = useStyles(props)

  return (
    <FormControl required error={props.error}>
      <FormControlLabel
        className={classes.ruleAgreementContainer}
        control={
          <Checkbox
            required
            disabled={props.disabled}
            checked={props.value}
            onChange={() => {
              props.onChange(!props.value)
            }}
          />
        }
        label={
          <Trans i18nKey="participate.agreement">
            I have read and agree to the{" "}
            {
              <Link
                className={classes.link}
                target="_blank"
                href={CLIENT_ROUTES.FAQ}
              >
                rules
              </Link>
            }
            .
          </Trans>
        }
      />
      <FormHelperText error={props.error}>{props.helperText}</FormHelperText>
    </FormControl>
  )
}

const getParticipantCredentialsForm = ({
  isNameRequired,
  submitButtonLabel,
  isAgreementRequired,
  isPhoneRequired,
  isPasswordResetLinkRequired,
}: {
  isNameRequired: boolean
  submitButtonLabel: string
  isAgreementRequired: boolean
  isPhoneRequired: boolean
  isPasswordResetLinkRequired: boolean
}) => ({
  handleSubmit,
  submitting,
  hasValidationErrors,
  dirty,
}: FormRenderProps<FormValues>) => {
  const { t } = useTranslation()
  const classes = useStyles()
  return (
    <form noValidate onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        {isNameRequired && (
          <Grid item xs={12}>
            <Field
              name="name"
              label={t("register.name")}
              disabled={submitting}
              component={TextField}
              required
              fullWidth
              autoFocus
            />
          </Grid>
        )}
        {isPhoneRequired && (
          <Grid item xs={12}>
            <Field
              name="phone"
              label={t("register.phone")}
              disabled={submitting}
              component={TextField}
              required
              fullWidth
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <Field
            name="email"
            label={t("register.email")}
            component={TextField}
            disabled={submitting}
            autoFocus={!isNameRequired}
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name="password"
            label={t("register.password")}
            component={PasswordField}
            disabled={submitting}
            required
            fullWidth
          />
          {isPasswordResetLinkRequired && (
            <Box mt={0.5}>
              <ForgotPasswordLink />
            </Box>
          )}
        </Grid>
        {isAgreementRequired && (
          <Grid item xs={12}>
            <Field
              name="isRulesAgreed"
              label={t("register.agreement")}
              required
              component={RuleAgreement}
              disabled={submitting}
              fullWidth
            />
          </Grid>
        )}
        <Grid item container justifyContent="center">
          <Grid item xs>
            <Box mt={1}>
              <ProgressButton
                variant="outlined"
                color="secondary"
                type="submit"
                size="large"
                progress={submitting}
                disabled={submitting || hasValidationErrors}
                className={classes.submitButton}
              >
                {submitButtonLabel}
              </ProgressButton>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </form>
  )
}

interface UserCredentialsResponse {
  user: {
    email: string
    role: {
      name: string
    }
    city: {
      id: number
      name: string
    }
  }
  jwt: string
}

interface Props {
  setError: SetError
}

const LoginForm = ({ setError }: Props) => {
  const context = React.useContext(UserContext)

  const { t } = useTranslation()
  const schema = object().shape({
    email: string()
      .email()
      .required(),
    password: string().required(),
  })

  const handleSubmit = async ({ email, password }: FormValues) =>
    axios
      .post(getEnvURL(API_ENDPOINTS.LOGIN), {
        identifier: email,
        password: password,
      })
      .then(
        ({
          data: {
            user: { role, city, email },
            jwt,
          },
        }: AxiosResponse<UserCredentialsResponse>) => {
          context &&
            context.setUser({
              email,
              token: jwt,
              role: role.name,
              cityId: city && city.id,
              cityName: city && city.name,
            })
          navigate(CLIENT_ROUTES.PARTICIPATE.HOME)
          return undefined
        }
      )
      .catch(({ response }) => {
        setError(response)
        return {}
      })

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={{ email: "", password: "" }}
      validationSchema={schema}
      render={getParticipantCredentialsForm({
        isNameRequired: false,
        submitButtonLabel: t("button.login"),
        isAgreementRequired: false,
        isPhoneRequired: false,
        isPasswordResetLinkRequired: true,
      })}
    />
  )
}

export const ParticipantLoginForm = withErrorNotification(LoginForm)

export const RegistrationForm = ({ setError }: Props) => {
  const { t } = useTranslation()
  const context = React.useContext(UserContext)
  const schema = object().shape({
    name: string().required(),
    phone: string().required(),
    email: string()
      .email()
      .required(t("validation.required")),
    password: string()
      .required()
      .min(6)
      .matches(/[0-9]+/, t("validation.require-number")),
    isRulesAgreed: boolean().oneOf([true], t("validation.agreement")),
  })

  const handleSubmit = ({ email, password, name, phone }: FormValues) =>
    axios
      .post(getEnvURL(API_ENDPOINTS.REGISTER), {
        username: email,
        name,
        email,
        password,
        phone,
      })
      .then(
        ({
          data: {
            user: { role, city, email },
            jwt,
          },
        }: AxiosResponse<UserCredentialsResponse>) => {
          navigate(CLIENT_ROUTES.PARTICIPATE.HOME)
          context.setUser({
            email,
            token: jwt,
            role: role.name,
            cityId: city && city.id,
            cityName: city && city.name,
          })
          return undefined
        }
      )
      .catch(({ response }) => {
        setError(response)
        return {}
      })

  const initial: FormValues = {
    email: "",
    phone: "",
    password: "",
    name: "",
    isRulesAgreed: false,
  }

  return (
    <Form
      onSubmit={handleSubmit}
      validationSchema={schema}
      initialValues={initial}
      render={getParticipantCredentialsForm({
        isNameRequired: true,
        submitButtonLabel: t("button.register"),
        isAgreementRequired: true,
        isPhoneRequired: true,
        isPasswordResetLinkRequired: false,
      })}
    />
  )
}

export const ParticipantRegistrationForm = withErrorNotification(
  RegistrationForm
)
