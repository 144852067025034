import { useQuery } from "@apollo/react-hooks"
import { MenuItem } from "@material-ui/core"
import Grid from "@material-ui/core/Grid/Grid"
import { ProgressButton } from "components/progress-button"
import { SelectField } from "components/select-field"
import gql from "graphql-tag"
import { object, string } from "yup"
import React from "react"
import { useTranslation } from "react-i18next"
import { Form } from "components/final-form/form"
import { Field } from "components/final-form/field"

const GENRES = gql`
  {
    genres {
      id
      name
      name_en
      name_ua
    }
  }
`

const validationSchema = object().shape({
  genre: string()
    .required()
    .nullable(),
})

export const AddMissingGenre = ({ onSubmit }) => {
  const { data: genreData } = useQuery(GENRES)
  const { t, i18n } = useTranslation()
  return (
    <Form
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      render={props => {
        return (
          <form noValidate onSubmit={props.handleSubmit}>
            <div>
              <Grid item>
                <Field
                  name="genre"
                  label={t("participate.genre")}
                  component={SelectField}
                  disabled={false}
                  fullWidth
                  autoFocus
                  required
                >
                  {genreData &&
                    genreData.genres.map(
                      ({ id, name, name_en, name_ua }: Genre) => (
                        <MenuItem key={id} value={id}>
                          {i18n.language === "lt-LT"
                            ? name
                            : i18n.language === "en-US"
                            ? name_en
                            : name_ua}
                        </MenuItem>
                      )
                    )}
                </Field>
              </Grid>
              <Grid item container justifyContent="flex-end">
                <ProgressButton
                  variant="contained"
                  color="primary"
                  size="large"
                  type="submit"
                  disabled={false}
                  progress={false}
                >
                  {t("button.next")}
                </ProgressButton>
              </Grid>
            </div>
          </form>
        )
      }}
    />
  )
}
