import L from 'leaflet'
// @ts-ignore
import logo from 'images/gmd-logo.svg'
// @ts-ignore
import logoSelected from 'images/gmd-logo-selected.svg'

export const createGMDMapIcon = () => {
  return new L.Icon({
    iconUrl: logo,
    iconSize: new L.Point(40, 40),
  });
}

export const createGMDMapIconSelected = () => {
  return new L.Icon({
    iconUrl: logoSelected,
    iconSize: new L.Point(40, 40),
  });
}
