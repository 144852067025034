import React, { useState, ChangeEvent } from "react"
import axios from "axios"
import {
  ParticipantLoginForm,
  ParticipantRegistrationForm,
} from "./components/participant-authorisation"
import { Tab, Tabs, Box, Grid, makeStyles } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"

import { Layout } from "components/layouts/main-layout"

const useStyles = makeStyles({
  root: {
    height: "100%",
  },
})

const getRegistrationStatus = () => {
  return axios.get(
    `${process.env.GATSBY_API_URL}/global-settings/registration-status`,
    {
      headers: {},
    }
  )
}

export const CredentialsTypeSelection = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [currentTabIndex, setValue] = useState(0)
  const handleChange = (_: ChangeEvent<{}>, tabIndex: number) => {
    setValue(tabIndex)
  }

  const Form = () => {
    const { data } = useQuery("isRegistrationOpen", getRegistrationStatus)
    const isRegistrationOpen = data && data.data.isRegistrationOpen
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className={classes.root}
      >
        <Grid item xs={12}>
          <Box maxWidth={400} margin="auto">
            <Tabs
              value={currentTabIndex}
              onChange={handleChange}
              variant="fullWidth"
            >
              <Tab key={"login"} label={t("button.login")} />
              {isRegistrationOpen && (
                <Tab key={"register"} label={t("button.register")} />
              )}
            </Tabs>
            <Box p={6}>
              {currentTabIndex === 0 && <ParticipantLoginForm />}
              {currentTabIndex === 1 && <ParticipantRegistrationForm />}
            </Box>
          </Box>
        </Grid>
      </Grid>
    )
  }

  return (
    <Layout pageName={t("page.login")}>
      <Form />
    </Layout>
  )
}
