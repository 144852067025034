import React from "react"
import { navigate } from "@reach/router"
import { useQuery as useRestQuery } from "react-query"
import { Grid, Box } from "@material-ui/core"
import axios from "axios"
import { useTranslation } from "react-i18next"

import { Layout } from "components/layouts/main-layout"
import { MediaCard } from "components/media-card"
import CLIENT_ROUTES from "constants/endpoints"
import { createURL } from "utilities"
import { Alert } from "components/alert"
import { Performer, UserStatus as Status } from "types"
import { getUserToken } from "services/auth/auth"

const getRegistrationStatus = () => {
  return axios.get(
    `${process.env.GATSBY_API_URL}/global-settings/registration-status`,
    {
      headers: {},
    }
  )
}

const getUserInformation = () => {
  return axios.get(`${process.env.GATSBY_API_URL}/users/me`, {
    headers: {
      Authorization: `Bearer ${getUserToken()}`,
    },
  })
}
const getOwnPerformers = () => {
  return axios.get(`${process.env.GATSBY_API_URL}/performers/own`, {
    headers: {
      Authorization: `Bearer ${getUserToken()}`,
    },
  })
}

const UserStatus = (props: { verificationStatus: Status }) => {
  const { t } = useTranslation()
  switch (props.verificationStatus) {
    case Status.NEW:
      return <Alert type="warning">{t("participate.not-confirmed")}</Alert>
    case Status.CONFIRMED:
      return <Alert type="success">{t("participate.confirmed")}</Alert>
    case Status.DECLINED:
      return <Alert type="error">{t("participate.declined")}</Alert>
    default:
      return null
  }
}

export const ParticipantHome = () => {
  const { t } = useTranslation()
  const { data: registrationSettings } = useRestQuery(
    "isRegistrationOpen",
    getRegistrationStatus
  )
  const { data: userResponse } = useRestQuery("user", getUserInformation)
  const { data: performerResponse } = useRestQuery(
    "performers",
    getOwnPerformers,
    {
      cacheTime: 0,
    }
  )
  const isRegistrationOpen =
    registrationSettings && registrationSettings.data.isRegistrationOpen
  const user = userResponse && userResponse.data
  if (performerResponse && performerResponse.data.length === 0) {
    navigate(CLIENT_ROUTES.PARTICIPATE.CREATE)
  }

  return (
    <Layout
      pageName={t("participate.profile")}
      pageTitle={t("participate.profile")}
    >
      <Box p={2}>
        <Grid container spacing={4} justifyContent="center">
          {user && user["verification_status"] && (
            <Grid item xs={12}>
              <UserStatus verificationStatus={user["verification_status"]} />
            </Grid>
          )}
          {!isRegistrationOpen && (
            <Grid item xs={12}>
              <Alert type="warning">
                {t("participate.registration-closed")}
              </Alert>
            </Grid>
          )}
          {performerResponse &&
            performerResponse.data.map(({ id, name, image }: Performer) => (
              <Grid item key={name}>
                <MediaCard
                  key={id + name}
                  name={name}
                  link={createURL(CLIENT_ROUTES.PARTICIPATE.PROFILE, {
                    performerId: id,
                  })}
                  imageUrl={
                    image && `${process.env.GATSBY_API_URL}/${image.url}`
                  }
                />
              </Grid>
            ))}
          <Grid item>
            {isRegistrationOpen && (
              <MediaCard
                add
                name={t("participate.add-performer")}
                link={CLIENT_ROUTES.PARTICIPATE.CREATE}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </Layout>
  )
}
