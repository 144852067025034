import React, { useEffect, useState, ComponentType } from "react"
import { gql } from "apollo-boost"
import { useMutation, useQuery } from "@apollo/react-hooks"
import { navigate } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import {
  Grid,
  Box,
  Stepper,
  Step,
  StepLabel,
  Typography,
  StepIconProps,
} from "@material-ui/core"
import { Done } from "@material-ui/icons"
import { useTranslation } from "react-i18next"
import { path } from "ramda"
import clsx from "clsx"

import ENDPOINTS from "constants/endpoints"
import {
  PerformerRegistration,
  FormValues as SubmitPerformer,
} from "./performer-registration"
import {
  PerformanceLocation,
  FormValues as SubmitPerformance,
} from "./performance-location"
import { PerformanceTime, FormValues as SubmitTime } from "./performance-time"
import { uploadFile } from "services/upload"
import { FindPerformanceResponse, Performance, Performer } from "types"
import ERRORS from "constants/errors"
import { createURL } from "utilities"
import axios from "axios"
import { getUserToken } from "services/auth/auth"
import { AddMissingGenre } from "./add-missing-genre"

const getOwnPerformers = () => {
  return axios.get(`${process.env.GATSBY_API_URL}/performers/own`, {
    headers: {
      Authorization: `Bearer ${getUserToken()}`,
    },
  })
}

const useStyles = makeStyles(theme => ({
  title: {
    textTransform: "uppercase",
    fontWeight: "bold",
  },
  stepper: {
    padding: theme.spacing(1),
  },
  fullWidth: {
    width: "100%",
  },
}))

export interface FlowData {
  performerId?: string
  ownPerformers?: Array<Performer>
  performanceId?: string
  cityId?: string
  zoneId?: string
  isAcoustic?: boolean
  locationId?: string
  hourFrom?: number
  hourTo?: number
}

export interface StepProps {
  onSubmit: (data: any) => Promise<any> | Promise<undefined>
  data: FlowData
  previousStep?: () => void
  nextStep?: () => void
}

interface Props {
  performerId: string
  performanceId: string
}

const useStepIconStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    height: 30,
    width: 30,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.text.disabled,
    borderRadius: "50%",
  },
  colored: {
    backgroundColor: theme.palette.primary.main,
  },
  bold: {
    fontWeight: "bold",
  },
  icon: {
    zIndex: 1,
    fontSize: "1.2rem",
  },
}))

const StepIcon = ({ active, completed, icon }: StepIconProps) => {
  const classes = useStepIconStyles()

  return (
    <div
      className={clsx(classes.root, {
        [classes.colored]: active || completed,
      })}
    >
      {completed ? (
        <Done fontWeight="bold" className={classes.icon} />
      ) : (
        <Typography align="center" className={clsx({ [classes.bold]: active })}>
          {icon}
        </Typography>
      )}
    </div>
  )
}

interface Step {
  label: string
  title: string
  content: ComponentType<any>
  onSubmit: any
}

export const Wizard = (props: Props) => {
  const { performerId, performanceId } = props
  const [createPerformer] = useMutation(CREATE_PERFORMER)
  const [updatePerformer] = useMutation(UPDATE_PERFORMER)
  const [createPerformance] = useMutation(CREATE_PERFORMANCE)
  const [updatePerformance] = useMutation(UPDATE_PERFORMANCE)
  const classes = useStyles()
  const { t } = useTranslation()
  const [activeStep, setActiveStep] = React.useState(0)
  const [data, setData] = useState<FlowData>({ performerId, performanceId })
  useEffect(() => {
    getOwnPerformers().then(({ data: performerData }) => {
      setData({
        ...data,
        ownPerformers: performerData,
      })
    })
  }, [])
  const { data: performanceData } = useQuery<FindPerformanceResponse>(
    FIND_PERFORMANCE_TIME,
    {
      variables: {
        id: performanceId,
      },
      skip: performanceId === undefined,
    }
  )

  useEffect(() => {
    const performance = performanceData?.performance as Performance
    if (performance) {
      const { hourFrom, hourTo } = performance
      setData({
        ...data,
        hourFrom,
        hourTo,
      })
    }
  }, [performanceData])

  const handleNext = () => {
    if (activeStep < wizardSteps.length) {
      setActiveStep(prevActiveStep => prevActiveStep + 1)
    }
  }

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep(prevActiveStep => prevActiveStep - 1)
    }
  }

  const submitPeformer = (currentWizardData: FlowData) => async ({
    name,
    website,
    about,
    image,
    membersCount,
    genre,
  }: SubmitPerformer) => {
    let imageId
    if (image) {
      const response = await uploadFile(image as File)
      const errorId = path(
        ["response", "data", "message", 0, "messages", 0, "id"],
        response
      )
      if (errorId && errorId === ERRORS.ULOAD_SIZE_LIMIT) {
        return { image: t("validation.upload-size") }
      } else {
        imageId = response
      }
    }

    return createPerformer({
      variables: {
        name,
        genre,
        membersCount: parseInt(membersCount as string, 10),
        website,
        about,
        imageId,
      },
    })
      .then((response: any) => {
        setData({
          performerId: response.data.createPerformer.performer.id,
        })
        handleNext()
        return undefined
      })
      .catch((response: any) => {
        const errorCode = path(
          ["graphQLErrors", 0, "extensions", "exception", "code"],
          response
        )
        if (
          errorCode === ERRORS.SQLITE_CONSTRAINT ||
          errorCode === ERRORS.DUP_ENTRY
        ) {
          return { name: t("validation.performer-unique") }
        }
        return {}
      })
  }

  const submitLocation = (currentWizardData: FlowData) => ({
    locationId,
    zoneId,
    cityId,
    isAcoustic,
  }: SubmitPerformance) => {
    setData({
      ...currentWizardData,
      locationId,
      zoneId,
      cityId,
      isAcoustic,
    })
    handleNext()
    return undefined
  }

  const submitTime = (data: FlowData) => ({ interval }: SubmitTime) => {
    const { performanceId, locationId } = data
    if (performanceId) {
      return updatePerformance({
        variables: {
          id: performanceId,
          ...interval,
          location: locationId,
          performer: props.performerId,
        },
      })
        .then(() => {
          handleNext()

          return undefined
        })
        .catch(() => {
          return {}
        })
    }
    return createPerformance({
      variables: {
        ...interval,
        location: locationId,
        performer: data.performerId,
      },
    })
      .then(() => {
        handleNext()

        return undefined
      })
      .catch(() => {
        return {}
      })
  }

  const [wizardSteps, setSteps] = useState<Step[]>([
    {
      label: t("wizard.location"),
      title: t("participate.select-location"),
      content: PerformanceLocation,
      onSubmit: submitLocation,
    },
    {
      label: t("wizard.time"),
      title: t("participate.select-time"),
      content: PerformanceTime,
      onSubmit: submitTime,
    },
  ])

  const performer: Performer =
    data.performerId &&
    data.ownPerformers?.find(
      ({ id }: Performer) => id.toString() === performerId.toString()
    )
  const performerGenre = performer && performer.genre
  useEffect(() => {
    const isGenreStepPresent = wizardSteps.some(
      ({ title }) => title === t("participate.add-genre")
    )
    if (
      performerId &&
      !performerGenre &&
      !isGenreStepPresent &&
      data.ownPerformers
    ) {
      const updatePerformerGenreStep: Step = {
        label: t("wizard.genre"),
        title: t("participate.add-genre"),
        content: AddMissingGenre,
        onSubmit: ({ ownPerformers }: FlowData) => ({ genre }: any) => {
          const performer: Performer = ownPerformers.find(
            ({ id }: Performer) => id.toString() === performerId.toString()
          )
          updatePerformer({
            variables: {
              ...performer,
              genre,
            },
          }).then(handleNext)
        },
      }
      setSteps([updatePerformerGenreStep, ...wizardSteps])
    }
  }, [performerId, performerGenre])

  useEffect(() => {
    const isRegistrationStepPresent = wizardSteps.some(
      ({ title }) => title === t("participate.register-performer")
    )
    if (!performerId && !isRegistrationStepPresent) {
      const createPerformerStep: Step = {
        label: t("wizard.register"),
        title: t("participate.register-performer"),
        content: PerformerRegistration,
        onSubmit: submitPeformer,
      }
      setSteps([createPerformerStep, ...wizardSteps])
    }
  }, [])

  const getStepTitle = (stepIndex: number) =>
    wizardSteps[stepIndex] ? wizardSteps[stepIndex].title : ""

  const CompleteStep = () => {
    useEffect(() => {
      window &&
        window.setTimeout(() => {
          if (performerId) {
            navigate(createURL(ENDPOINTS.PARTICIPATE.PROFILE, { performerId }))
          } else {
            navigate(ENDPOINTS.PARTICIPATE.HOME)
          }
        }, 2000)
    }, [])

    return (
      <Grid container justifyContent="center" alignItems="center">
        <Grid item>
          <Typography>{t("participate.congratulations")}</Typography>
        </Grid>
      </Grid>
    )
  }

  const getStepContent = () => {
    if (activeStep === wizardSteps.length) {
      return <CompleteStep />
    } else {
      const Content = wizardSteps[activeStep].content
      const onSubmit = wizardSteps[activeStep].onSubmit
      // @ts-ignore
      return (
        <Content
          previousStep={handleBack}
          nextStep={handleNext}
          onSubmit={onSubmit(data)}
          data={data}
        />
      )
    }
  }

  return (
    <Box pb={2} width="100%">
      <Grid container justifyContent="center" alignItems="center">
        <Box mt={3} mb={3}>
          <Typography align="center" variant="h4" className={classes.title}>
            {getStepTitle(activeStep)}
          </Typography>
        </Box>
        <Grid item xs={12} className={classes.fullWidth}>
          <Stepper
            className={classes.stepper}
            activeStep={activeStep}
            alternativeLabel
          >
            {wizardSteps.map(({ label }) => (
              <Step key={label}>
                <StepLabel StepIconComponent={StepIcon}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Box p={1} mt={2}>
            {getStepContent()}
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

const FIND_PERFORMANCE_TIME = gql`
  query performance($id: ID!) {
    performance(id: $id) {
      hourFrom
      hourTo
    }
  }
`

const CREATE_PERFORMER = gql`
  mutation CreatePerformer(
    $name: String!
    $genre: ID!
    $membersCount: Int!
    $website: String
    $about: String
    $imageId: ID
  ) {
    createPerformer(
      input: {
        data: {
          name: $name
          genre: $genre
          membersCount: $membersCount
          website: $website
          about: $about
          image: $imageId
        }
      }
    ) {
      performer {
        id
      }
    }
  }
`

const UPDATE_PERFORMER = gql`
  mutation UpdatePerformer(
    $id: ID!
    $name: String!
    $genre: ID!
    $membersCount: Int!
    $website: String
    $about: String
    $imageId: ID
  ) {
    updatePerformer(
      input: {
        where: { id: $id }
        data: {
          name: $name
          genre: $genre
          membersCount: $membersCount
          website: $website
          about: $about
          image: $imageId
        }
      }
    ) {
      performer {
        id
      }
    }
  }
`

const CREATE_PERFORMANCE = gql`
  mutation CreatePerformance(
    $location: ID!
    $performer: ID!
    $hourFrom: Float!
    $hourTo: Float!
  ) {
    createPerformance(
      input: {
        data: {
          location: $location
          performer: $performer
          hourFrom: $hourFrom
          hourTo: $hourTo
        }
      }
    ) {
      performance {
        id
      }
    }
  }
`

const UPDATE_PERFORMANCE = gql`
  mutation UpdatePerformance(
    $id: ID!
    $location: ID!
    $performer: ID!
    $hourFrom: Float!
    $hourTo: Float!
  ) {
    updatePerformance(
      input: {
        where: { id: $id }
        data: {
          location: $location
          performer: $performer
          hourFrom: $hourFrom
          hourTo: $hourTo
        }
      }
    ) {
      performance {
        id
      }
    }
  }
`
