import React, { useState, createRef, ReactNode } from "react"
import { gql } from "apollo-boost"
import { useQuery } from "@apollo/react-hooks"
import { Map, TileLayer, Marker, Popup } from "react-leaflet"
import {
  Grid,
  Box,
  Button,
  FormHelperText,
  Typography,
  makeStyles,
} from "@material-ui/core"
import { DeleteOutlined } from "@material-ui/icons"
import { useTranslation } from "react-i18next"
import { LatLngTuple } from "leaflet"
import { pathOr } from "ramda"

import { createGMDMapIcon, createGMDMapIconSelected } from "components/map-icon"
import { Location, FindLocations } from "types"
import { useQuery as useQueryRest } from "react-query"
import axios, { AxiosPromise } from "axios"
import { getUserToken } from "services/auth/auth"

interface Props {
  center: string
  onChange: (value: string | null) => void
  cityId: string
  zoneId?: string
  value?: string
  isAcoustic?: boolean
  helperText?: ReactNode
  error?: boolean
}
const useStyles = makeStyles(theme => ({
  marker: {
    background: "transparent",
    border: "none",
  },
  locationName: {
    textAlign: "center",
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.fontSize,
    marginBottom: theme.spacing(1),
  },
  locationDescription: {
    marginBottom: theme.spacing(1),
  },
}))

const getFullLocations = ({
  cityId,
}: {
  cityId: string
}): AxiosPromise<{
  fullLocations: string[]
}> => {
  return axios.get(`${process.env.GATSBY_API_URL}/locations/${cityId}/full`, {
    headers: {
      Authorization: `Bearer ${getUserToken()}`,
    },
  })
}

export const LocationSelector = ({
  center,
  zoneId,
  cityId,
  isAcoustic,
  value,
  onChange,
  error,
  helperText,
}: Props) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const DEFAULT_CENTER = {
    lat: 54.6867244,
    lng: 25.2803737,
  }

  const [selected, setSelected] = useState<Location>()
  const { data: fullLocationsData, isLoading } = useQueryRest(
    [
      "full-locations",
      {
        cityId: cityId,
      },
    ],
    getFullLocations,
    {
      refetchInterval: false,
    }
  )
  const fullLocations =
    (fullLocationsData && fullLocationsData.data.fullLocations) || []
  const { data: locationsData } = useQuery<FindLocations>(FIND_LOCATIONS, {
    fetchPolicy: "network-only",
    variables: {
      city: cityId,
      zone: zoneId,
      is_acoustic: isAcoustic,
    },
  })
  if (!selected && locationsData?.locations) {
    const location = locationsData.locations?.find(
      (location: Location) => location.id === value
    )
    if (location) {
      setSelected(() => location)
    }
  }

  // @ts-ignore
  const locationToPos = (loc: string): LatLngTuple =>
    loc.split(",").map(coord => coord.trim())

  const position: LatLngTuple = [DEFAULT_CENTER.lat, DEFAULT_CENTER.lng]
  const popupRef = createRef()

  const closePopup = () => {
    // @ts-ignore
    popupRef.current.leafletElement.options.leaflet.map.closePopup()
  }

  const handleSelect = (location: Location) => () => {
    onChange(location.id)
    setSelected(location)
    closePopup()
    // on mobile the map covers a lot of vertical space, scrolling helps show there's more stuff on bottom
    window.scrollTo(0, document.body.scrollHeight)
  }
  const handleRemove = () => {
    setSelected(undefined)
    onChange(null)
  }
  const markers = pathOr([], ["locations"], locationsData)
    .filter(
      ({ id }) => !fullLocations.some(fullLocationId => fullLocationId == id)
    ) // using == because ids are mismatched sometimes ('1' vs 1)
    .map((location: Location) => (
      <Marker
        icon={
          selected && selected.id === location.id
            ? createGMDMapIconSelected()
            : createGMDMapIcon()
        }
        key={location.coordinates}
        position={locationToPos(location.coordinates)}
        className={classes.marker}
      >
        <Popup ref={popupRef}>
          <Grid container direction="column" justifyContent="center">
            <Grid item className={classes.locationName}>
              {location.name}
            </Grid>
            {location.description && (
              <Grid item className={classes.locationDescription}>
                {location.description}
              </Grid>
            )}

            <Grid item container justifyContent="center">
              <Button
                variant="contained"
                color="primary"
                onClick={handleSelect(location)}
              >
                {t("button.select")}
              </Button>
            </Grid>
          </Grid>
        </Popup>
      </Marker>
    ))

  return (
    <>
      <FormHelperText error={error}>{helperText}</FormHelperText>
      <link
        rel="stylesheet"
        href="https://unpkg.com/leaflet@1.5.1/dist/leaflet.css"
        integrity="sha512-xwE/Az9zrjBIphAcBb3F6JVqxf46+CDLwfLMHloNu6KEQCAWi6HcDUbeOfBIptF7tcCzusKFjFw2yuvEpDL9wQ=="
        crossOrigin=""
      />
      <Map
        center={locationToPos(center) || position}
        zoom={13}
        style={{ height: "500px" }}
      >
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        {markers}
      </Map>
      {selected && (
        <>
          <Box pt={2} display="flex" justifyContent="center">
            <Typography>
              {t("components.location", { location: selected.name })}
            </Typography>
          </Box>
          <Box pt={2} display="flex" justifyContent="center">
            <Button
              variant="contained"
              color="primary"
              endIcon={<DeleteOutlined />}
              onClick={handleRemove}
            >
              {t("button.change")}
            </Button>
          </Box>
        </>
      )}
    </>
  )
}

const FIND_LOCATIONS = gql`
  query locations($city: ID!, $zone: ID, $is_acoustic: Boolean) {
    locations(
      where: { city: $city, zone: { id: $zone, is_acoustic: $is_acoustic } }
    ) {
      name
      id
      description
      coordinates
      city {
        id
      }
    }
  }
`
